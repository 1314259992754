import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import {useLax} from "../../helpers/use-lax";
import Loader from "./../../components/Loader/Loader";
import HeaderOne from "../../components/Header/HeaderOne";

import HeroSliderSection from "../../components/Hero/HeroSliderSection";
import HeroSliderSingle from  "../../components/Hero/HeroSliderSingle";
import CTAOne from "../../components/CTA/CTAOne";
import dataSlider from "../../data/Slider/creative-agency-data.json";
import FooterOne from "../../components/Footer/FooterOne";
import Portfolio from "../../components/Portfolio/Portfolio";

const CreativeAgency = () => {
  useLax();
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <Loader>
      <HeaderOne />
      <HeroSliderSection data={dataSlider} />
      {/* <HeroSliderSingle data={dataSlider}/> */}
      <Portfolio columns="3" layout="wide" items="6" classAppend="pt-0" />
      {/* <CTAOne
        tagline="careers"
        title="Let's write your story, together."
        textButton="Contact us"
        textLink="!#"
        bg="dark"
      >
        We do not tell you our story. We write it together. Partnering with us
        means a seat at the table where you will be heard.
      </CTAOne> */}
      <FooterOne />
    </Loader>
  );
};

export default CreativeAgency;
